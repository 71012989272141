import {
  PROPERTY_NAMESPACE,
  TRANSACTION_NAMESPACE,
  TRANSACTION_PACKAGE_NAMESPACE,
} from 'src/stores/pdf-annotations-store';

interface NamespaceScopeParams {
  transactionPackageId?: string;
  propertyInfoId?: string;
}

interface NamespaceScopeResult {
  namespace: string;
  itemId: string | null;
}

export function getNamespaceScope({
  transactionPackageId,
  propertyInfoId,
}: NamespaceScopeParams = {}): NamespaceScopeResult {
  if (transactionPackageId) {
    return {
      namespace: TRANSACTION_PACKAGE_NAMESPACE,
      itemId: transactionPackageId,
    };
  }

  if (propertyInfoId) {
    return {
      namespace: PROPERTY_NAMESPACE,
      itemId: propertyInfoId,
    };
  }

  return {
    namespace: TRANSACTION_NAMESPACE,
    itemId: null,
  };
}

export function getNamespaceScopeKey(params: NamespaceScopeParams): string {
  const { namespace, itemId } = getNamespaceScope(params);
  return [namespace, itemId].filter(Boolean).join(':');
}
