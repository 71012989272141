import { computed, makeObservable } from 'mobx';
import type Item from 'src/models/transactions/items/item';
import type { ValidTransactionItemKind } from 'src/types/api';
import { getNamespaceScope, getNamespaceScopeKey } from './utils';

export default class ItemNamespace<TKind extends ValidTransactionItemKind> {
  item: Item<TKind>;

  constructor(item: Item<TKind>) {
    makeObservable(this);
    this.item = item;
  }

  @computed
  get hasNamespaceScopeEdgeKinds(): boolean {
    return (
      Object.entries(this.item.namespaceScopeEdgeKinds || {}).filter(
        ([k, v]) => k && v
      ).length > 0
    );
  }

  findEdgeItemIdByKind(kind: string) {
    return this.item.inEdges.find((e) => e.kind === kind)?.item1Id;
  }

  getNamespaceSourceItemId(kind: string, edgeKindsKey: string) {
    if (this.item.kind === kind) {
      return this.item.id;
    }

    if (!this.hasNamespaceScopeEdgeKinds) {
      return undefined;
    }
    return this.findEdgeItemIdByKind(
      this.item.namespaceScopeEdgeKinds?.[edgeKindsKey]
    );
  }

  @computed
  get transactionPackageId() {
    return this.getNamespaceSourceItemId(
      'TRANSACTION_PACKAGE',
      'transactionPackageEdgeKind'
    );
  }

  @computed
  get propertyInfoId() {
    return this.getNamespaceSourceItemId(
      'PROPERTY_INFO',
      'propertyInfoEdgeKind'
    );
  }

  @computed
  get namespaceScopeParams() {
    return {
      transactionPackageId: this.transactionPackageId,
      propertyInfoId: this.propertyInfoId,
    };
  }

  @computed
  get namespaceScope() {
    return getNamespaceScope(this.namespaceScopeParams);
  }

  @computed
  get namespaceScopeKey() {
    return getNamespaceScopeKey(this.namespaceScopeParams);
  }
}
